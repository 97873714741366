import { IconButton, Tooltip } from '@material-ui/core';
import FlowsUrls from 'components/Menus/FlowsUrls';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import { useCallback, useEffect, useState } from 'react';
import {
  DeleteGroup,
  ReleaseGroupAccess,
  UpdateLastAccess,
} from 'services/FlowService';
import { IDeleteGroupRequest } from 'services/FlowService/types';
import {
  Container,
  GridHeader,
  GridInputsHeader,
  TooltipContainer,
} from './styles';
import ToastNotification from './ToastNotification';

import { IEntity } from 'models/Entity';
import { Key } from 'services/AccessKeysService/types';
import createBotObject from 'utils/CreateBotObject';
import exportBot from 'utils/ExportBot';
import {
  createAndUpdateGroups,
  removeNonExistingGroups,
  updateEntities,
  updateKeys,
  updatePrincipalGroup,
} from 'utils/ImportBot/ImportBotService';
import { localStore } from 'utils/Stores';

import { isValidPrincipalGroup } from 'utils/Helpers';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import BugReportIcon from '@material-ui/icons/BugReport';
import useTranslator from 'utils/hooks/Translator';

import flexGroupsTreatment from 'components/Flow/utils/FlexGroupsTreatment';
import AIIcon from 'components/Icons/AIIcon';
import { useBuilder } from 'contexts/Builder';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import Flow from 'pages/Flow';
import { DeleteFlexBlockGroup } from 'services/FlexBlocksService';
import useAgents from 'utils/hooks/useAgents';
import { BuilderModals } from './BuilderModals';
import { PublishButton } from './PublishButton';
import RenderBots from './RenderBots';

interface IExportGroup {
  groupName: string;
  blocks: any;
}

export default function MainComponent({
  usesAirTalk = false,
}: {
  usesAirTalk?: boolean;
}) {
  const { currentData, setCurrentData } = useFintalkCloud();
  const { dispatch, saveFlow, toastNotification, state, loadFlow } = useFlow();
  const { state: appState } = useApp();
  const { signed, load } = appState;

  const { isAgentFreeToUse } = useAgents();

  const dispatchApp = useApp().dispatch;
  const { groupsNames } = state;

  const [errors, setErrors] = useState<string[]>([]);

  const [openFlowTest, setOpenFlowTest] = useState(false);
  const [anchorElFlow, setAnchorElFlow] = useState(null);
  const [botToRestore, setBotToRestore] = useState('');

  const { getTranslation } = useTranslator();
  const {
    handleBuilderDispatch,
    state: builderState,
    dispatch: builderDispatch,
  } = useBuilder();

  const botName = currentData.agentName;

  const importBot = useCallback(async () => {
    const botLiberated = await isAgentFreeToUse();

    if (!botLiberated) return;

    const documentHtml = document.getElementById('drawflow');
    const uploadInput = document.createElement('input');
    uploadInput.type = 'file';
    uploadInput.accept = 'application/json';
    handleBuilderDispatch('closeModalImportExportBot');

    const currentBot = await createBotObject(botName || '', dispatchApp);
    const currentBotStringified = JSON.stringify(currentBot);

    setBotToRestore(currentBotStringified);

    uploadInput.onchange = async (e: any) => {
      const file = e.target.files?.item(0);
      const fileText = await file.text();
      const drawflow = JSON.parse(fileText);

      const groups: IExportGroup[] = drawflow.groups;
      const entities: IEntity[] = drawflow.entities;
      const importKeys: Key[] = drawflow.accessKeys;

      let requestErrors = [];
      setErrors([]);

      if (!groups || !entities) {
        requestErrors.push(
          getTranslation('validations.invalidJsonFormatImportAgent')
        );
      }

      if (requestErrors.length <= 0) {
        const principalGroup = groups.find((g) => g.groupName === 'principal');
        const principalGroupData = Object.values(
          principalGroup?.blocks.drawflow.Home.data
        );

        if (!isValidPrincipalGroup(principalGroupData)) {
          setErrors([getTranslation('validations.principalWithoutMainBlocks')]);
          handleBuilderDispatch('openModalImportError');
          return;
        }
        dispatch({ type: 'updateForm', data: { loading: true } });

        const promisesResults = await Promise.all([
          await updatePrincipalGroup(botName || '', groups, dispatchApp),
          await removeNonExistingGroups(
            botName || '',
            groups,
            groupsNames,
            dispatchApp
          ),
          await createAndUpdateGroups(
            botName || '',
            groups,
            groupsNames,
            dispatchApp
          ),
          await updateEntities(botName || '', entities, dispatchApp),
          await updateKeys(botName || '', importKeys, dispatchApp),
        ]);

        requestErrors.push(
          promisesResults[0][0],
          promisesResults[1][0],
          promisesResults[2][0],
          promisesResults[3][0],
          promisesResults[4][0]
        );

        const updatedGroupsNames = groups.map((group) => group.groupName);

        dispatch({
          type: 'updateForm',
          data: { groupsNames: updatedGroupsNames },
        });

        dispatch({ type: 'updateForm', data: { loading: false } });
      }

      requestErrors = requestErrors
        .filter((error) => (error !== undefined ? error : ''))
        .filter((error) => error !== '');
      setErrors(requestErrors);

      if (requestErrors.length > 0) {
        handleBuilderDispatch('openModalImportError');
      } else {
        toastNotification(
          'success',
          getTranslation('toast.success.importAgent')
        );
        await flexGroupsTreatment(botName || '', groups, dispatchApp);

        await loadFlow('principal', botName || '');
        dispatch({
          type: 'setIdGroup',
          data: { idGroup: 'principal' },
        });
      }
    };

    setCurrentData((prev) => ({ ...prev, groupName: 'princpal', importAgent: false }));
    documentHtml?.appendChild(uploadInput);
    uploadInput.click();
    uploadInput.parentNode?.removeChild(uploadInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.importAgent]);

  useEffect(() => {
    if (currentData.importAgent) importBot();
  }, [currentData.importAgent, importBot]);

  useEffect(() => {
    if (currentData.exportAgent) {
      exportBot(currentData.agentName, dispatchApp);
    }
    return () => {
      setCurrentData((prev) => ({ ...prev, exportAgent: false }));
    };
  }, [
    currentData.agentName,
    currentData.exportAgent,
    dispatchApp,
    setCurrentData,
  ]);

  const changeGroupFlow = async (group_id: string, deleteGroup = false) => {
    dispatch({ type: 'updateForm', data: { loading: true } });

    
    if (state.isEditing) {
      !deleteGroup && (await updateFlow());
      
      await ReleaseGroupAccess(
        {
          bot_name: botName || '',
          groupName: state.idGroup || '',
        },
        dispatchApp
      );
    }

    await loadFlow(group_id, botName || '', false, deleteGroup);
    dispatch({
      type: 'updateForm',
      data: { idGroup: group_id, loading: false },
    });
  };

  const updateFlow = async () => {
    const groupLastVersion = localStore.get('groupLastVersion') || '';

    if (
      !!state.drawFlowRef &&
      !!state.editorFlow &&
      state.isEditing &&
      JSON.stringify(state.editorFlow.drawflow) !== groupLastVersion
    ) {
      const sBotAndGroup = localStore.get('current_bot_group');

      if (sBotAndGroup) {
        const { botName, groupName } = JSON.parse(sBotAndGroup);

        if (botName && groupName) {
          await UpdateLastAccess(
            {
              bot_name: botName,
              groupName,
            },
            dispatchApp
          );
        }
      }
      await saveFlow();
    }
  };

  const handleDeleteGroup = async () => {
    if (!builderState.groupToDelete) {
      toastNotification('error', 'Nenhum grupo selecionado');
      return false;
    }

    if (!botName) {
      toastNotification('error', 'Nenhum bot selecionado');
      return false;
    }

    const request: IDeleteGroupRequest = {
      bot_name: botName,
      group_name: builderState.groupToDelete.id,
      group_name_confirmation: builderState.groupToDelete.id,
    };
    const result = await DeleteGroup(request, dispatchApp);

    if (result.Success) {
      const newGroupList = groupsNames.filter(
        (item) => item !== builderState.groupToDelete?.id
      );

      if (builderState.groupToDelete.id === state.idGroup) {
        const principalGroup = groupsNames.find((item) => item === 'principal');
        const groupSelected = principalGroup ? principalGroup : newGroupList[0];
        await changeGroupFlow(groupSelected, true);
      }

      dispatch({ type: 'updateForm', data: { groupsNames: newGroupList } });

      toastNotification('success', getTranslation('toast.success.deleteGroup'));

      const flexBlockName = builderState.groupToDelete.name.substring(
        5,
        builderState.groupToDelete.name.length
      );

      await DeleteFlexBlockGroup(
        { bot_name: botName, block_name: flexBlockName, groups_used: [] },
        dispatchApp
      );
    } else {
      toastNotification('error', getTranslation('toast.error.deleteGroup'));
    }
    builderDispatch({
      type: 'setGroupToDelete',
      data: { groupToDelete: undefined },
    });
  };

  if (load) return <></>;

  function handleOpenUrlsTests(event: any) {
    setAnchorElFlow(event.currentTarget);
    setOpenFlowTest(true);
  }

  function handleCloseUrlsTests() {
    setAnchorElFlow(null);
    setOpenFlowTest(false);
  }

  const renderBody = () => {
    return (
      <>
        <GridHeader>
          <GridInputsHeader>
            <RenderBots />
          </GridInputsHeader>

          <TooltipContainer>
            {usesAirTalk && botName && (
              <Tooltip title={'AirTalk'} placement="left">
                <IconButton
                  onClick={() => handleBuilderDispatch('openAirTalk')}
                  disabled={!state.isEditing}
                >
                  <AIIcon color={!state.isEditing ? '#ffffff6b' : '#fff'} />
                </IconButton>
              </Tooltip>
            )}

            {botName && (
              <Tooltip title={'Debug'} placement="left">
                <IconButton onClick={() => handleBuilderDispatch('openDebug')}>
                  <BugReportIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}

            {openFlowTest && (
              <FlowsUrls
                handleCloseUrlsTests={handleCloseUrlsTests}
                anchorElFlow={anchorElFlow}
                openFlowTest={openFlowTest}
                openFalazap={() => handleBuilderDispatch('openFalazap')}
              />
            )}

            {botName && (
              <Tooltip
                title={getTranslation('tooltip.executeFlow')}
                placement="bottom"
              >
                <IconButton
                  aria-label="execute-flow"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={openFlowTest ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleOpenUrlsTests}
                  size={'medium'}
                >
                  <PlayCircleFilledIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </TooltipContainer>
        </GridHeader>
      </>
    );
  };

  return (
    <Container>
      <Flow />

      {signed && renderBody()}

      <ToastNotification />

      <BuilderModals
        handleDeleteGroup={handleDeleteGroup}
        errors={errors}
        botToRestore={botToRestore}
      />

      {!!currentData.agentName && <PublishButton />}
    </Container>
  );
}
