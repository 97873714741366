import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@material-ui/core';
import { useForm } from 'contexts/Form/formContext';
import { BlockModeType, SendUser } from 'models/DataFlow';
import { useState } from 'react';
import useTranslator from 'utils/hooks/Translator';
  
  export function BlockModeComponent() {
    const { state, updateForm } = useForm();
    const { getTranslation } = useTranslator();
    const { dataForm } = state;
  
    const [blockMode, setBlockMode] = useState<BlockModeType>(dataForm?.blockMode || 'PASSIVE');
  
    const handleChangeBlockMode = (newMode: BlockModeType) => {
      setBlockMode(newMode)

      if (dataForm) {
        const cleanSendUser: SendUser = {
          message: '',
          messageDidNotUnderstood: '',
          url: '',
        }

        updateForm(
          { 
            ...dataForm, 
            blockMode: newMode,
            sendUser: newMode !== 'AUTOMATIC' ? dataForm.sendUser : cleanSendUser
          }
        );
      }
    };

    return (
        <FormControl 
          fullWidth
          error={!!state.formErrors?.blockMode}
        >
          <Select
            labelId='block-mode'
            id='block-mode'
            value={blockMode}
            onChange={(e) =>
                handleChangeBlockMode(String(e.target.value) as BlockModeType)
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '240px'
                }
              }
            }}
          >
            <MenuItem value='PASSIVE'>
                {getTranslation('passive')}
            </MenuItem>

            <MenuItem value='AUTOMATIC'>
              {getTranslation('automatic')}
            </MenuItem>
          </Select>
  
          {!!state.formErrors?.blockMode && (
            <FormHelperText>
              {state.formErrors.blockMode}
            </FormHelperText>
          )}
        </FormControl>
    )
}
  