import { MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useForm } from 'contexts/Form/formContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslator from 'utils/hooks/Translator';
import { validateVarNames } from 'utils/String';
import * as S from './styles';
import { GlobalVariableSelectionProps } from './types';

export default function GlobalVariablesSelection(
  props: GlobalVariableSelectionProps
) {
  const { showTitle, variant, noPadding } = props;
  const { state, dispatch } = useForm();
  const { dataForm } = state;
  const [prefixVar, setPrefixVar] = useState(
    dataForm?.varValue?.substring(0, 5) ?? 'vars.'
  );
  const varValue = useMemo(
    () =>
      dataForm?.varValue?.substring(5, dataForm?.varValue.length) ?? 'resposta',
    [dataForm]
  );

  const { getTranslation } = useTranslator();

  const load = useCallback(() => {
    if (dataForm && !dataForm.inputs) {
      if (!dataForm.varValue) {
        dataForm.varValue = 'vars.resposta';

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }

      if (!dataForm.messageType) {
        dataForm.messageType = 'text';

        dispatch({
          type: 'updateForm',
          data: {
            dataForm: {
              ...dataForm,
            },
          },
        });
      }
    }
  }, [dataForm, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeField = (
    e: React.ChangeEvent<
      | { name?: string | undefined; value: unknown }
      | HTMLTextAreaElement
      | HTMLInputElement
    >,
    field: 'varValue' | 'sendUser' | 'messageType'
  ) => {
    if (!!dataForm) {
      let validatedValue = String(e.target.value);

      if (field === 'sendUser' && !!dataForm.sendUser)
        dataForm.sendUser.messageDidNotUnderstood = validatedValue;
      else if (field === 'varValue') {
        validatedValue =
          prefixVar === 'vars.'
            ? validateVarNames(e, 'name', true)
            : validatedValue;
        dataForm[field] = prefixVar + validatedValue;

        const firstPosVariable = dataForm.inputs?.variables[0];

        if (
          dataForm.metadata?.type !== 'Multiple' &&
          dataForm.metadata?.type !== 'AI123' &&
          dataForm.metadata?.type !== 'Carousel'
        ) {
          dataForm.inputs = {
            variables: [
              {
                name: `${validatedValue}`,
                questions: firstPosVariable?.questions ?? [''],
                required: true,
                vartype: '@sys.any',
                defaultValue: '',
                isList: false,
                value: `$${validatedValue}`,
              },
            ],
          };
        }
      } else dataForm[field] = validatedValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const handleChangePrefix = () => {
    if (!!dataForm) {
      const updatedPrefix = prefixVar === 'vars.' ? 'user.' : 'vars.';
      setPrefixVar(updatedPrefix);

      dataForm['varValue'] = updatedPrefix + varValue;

      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  return (
    <S.ContentOutputVariable noPadding={noPadding}>
      {showTitle && (
        <Typography variant="body1">
          {getTranslation('outputVariable')}
        </Typography>
      )}
      <div>
        <Select
          value={prefixVar}
          variant={variant}
          onChange={() => handleChangePrefix()}
        >
          <MenuItem value={'vars.'}>vars.</MenuItem>
          <MenuItem value={'user.'}>user.</MenuItem>
        </Select>
        {prefixVar === 'vars.' ? (
          <TextField
            value={varValue}
            variant={variant}
            onChange={(e) => handleChangeField(e, 'varValue')}
          />
        ) : (
          <Select
            value={varValue}
            variant={variant}
            onChange={(e) => handleChangeField(e, 'varValue')}
          >
            <MenuItem value={'name'}>name</MenuItem>
            <MenuItem value={'nickname'}>nickname</MenuItem>
            <MenuItem value={'email'}>email</MenuItem>
            <MenuItem value={'phoneNumber'}>phoneNumber</MenuItem>
            <MenuItem value={'cpf'}>cpf</MenuItem>
            <MenuItem value={'birthDate'}>birthDate</MenuItem>
          </Select>
        )}
      </div>
    </S.ContentOutputVariable>
  );
}
