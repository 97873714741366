import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFlow } from 'contexts/Flow/flowContext';
import { useStyles } from './styles';

import {
  array_connectors,
  create_array_connectors,
} from 'components/BlocklyConstructor/compiler';
import GlobalVariablesSelection from 'components/inputs/GlobalVariablesSelection';
import TypeOfMessage from 'components/inputs/Material/TypeOfMessage';
import { useForm } from 'contexts/Form/formContext';
import EIntentType from 'enums/EIntentType';
import EListType from 'enums/EListType';
import useTranslator from 'utils/hooks/Translator';
import {
  formValidators,
  getMetaBlocksValidatorsIntentType,
} from 'validations/validations';
import { GroupChild } from './GroupChild';
import { TabPanel } from './TabPanel';
import { UserParameters } from './UserParameters';

export default function MetadataBlocksDrawerEditor() {
  const { state, dispatch, updateIntent } = useFlow();
  const { dispatch: dispatchForm, state: formState } = useForm();
  const { getTranslation } = useTranslator();
  const [loading, setLoading] = useState(false);
  const { dataForm } = formState;
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const blockGroups = [
    ...(dataForm?.metadata?.properties.groups || []),
    // { children: [{ title: 'Tags', items: [] }], label: 'Tags' },
  ];

  const translatedErrors = useMemo(
    () => ({
      intentNameRequired: getTranslation('validations.required', {
        field: getTranslation('name'),
      }),
      intentNameDuplicated: getTranslation('validations.intentNameDuplicated'),
      outputTitleRequired: getTranslation('validations.outputTitleRequired'),
      outputTitleDuplicated: getTranslation(
        'validations.outputTitleDuplicated'
      ),
      totalSum: getTranslation('validations.totalSum'),
      formMessage: getTranslation('validations.required', {
        field: getTranslation('message'),
      }),
      entryName: 'Por favor, informe o nome do bloco de entrada.',
      apiInfoUrl: 'Por favor, informe uma url válida.',
    }),

    [getTranslation]
  );

  useEffect(() => {
    if (!dataForm) return;
    if (dataForm.metadata?.type !== 'Api') return;

    if (!dataForm.apiReturnCodeTreatment) {
      dataForm.apiReturnCodeTreatment = `// variáveis retornadas da requisição: 
			// { response, statusCode, statusMessage }
			
			// retorno inteiro da requisição
			const resp = resposta.response; 
			
			// status da requisição
			const statusCode = resposta.statusCode;
			
			// mensagem de status
			const statusMessage = resposta.statusMessage;
			
			`.replaceAll('\t', '');
    }
    if (!dataForm.varValue) {
      dataForm.varValue = 'resposta';
    }
    dispatchForm({ type: 'updateForm', data: { dataForm } });
  }, [dataForm, dispatchForm]);

  const updateOutputs = useCallback(() => {
    if (!dataForm) return;
    if (
      array_connectors &&
      ![
        EIntentType.CarouselParent,
        EIntentType.MultipleChoiceParent,
        EIntentType.OptionsBlock,
      ].includes(dataForm.intentType)
    ) {
      let auxArray = [];
      if (![EIntentType.NewGroup].includes(dataForm.intentType)) {
        auxArray = !!array_connectors.length ? array_connectors : [];
      }

      const outputs = [];
      for (let index = 0; index < auxArray.length; index++) {
        outputs.push({
          title: auxArray[index],
        });
      }
      if (outputs.length > 0) dataForm.outputs = outputs;

      dispatchForm({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  }, [dataForm, dispatchForm]);

  const handleSave = useCallback(async () => {
    if (!dataForm) return;

    setLoading(true);

    const keys = getMetaBlocksValidatorsIntentType(dataForm.metadata!.type!);

    let formErrors = {};

    for (const key of keys) {
      if (key) {
        const data = state.editorFlow?.drawflow.drawflow.Home.data;
        const error = await formValidators[key](
          dataForm,
          translatedErrors,
          data,
          state.nodeId
        );
        formErrors = { ...formErrors, ...error };
      }
    };

    dispatchForm({ type: 'updateForm', data: { formErrors } });

    const isValid = !Object.keys(formErrors).length;

    if (isValid && dataForm.block_id) {
      if (dataForm.dataBlockly?.payload) {
        create_array_connectors(dataForm.dataBlockly?.payload);
        updateOutputs();
      }

      dispatch({ type: 'updateForm', data: { dataForm: dataForm } });

      await updateIntent(dataForm, Number(dataForm.block_id));

      const currGroup = JSON.stringify(state.editorFlow?.drawflow);

      localStorage.setItem('last_updated', currGroup);

      dispatch({ type: 'closeModalFormsNewEditor' });
    }

    setLoading(false);
  }, [
    dataForm,
    dispatch,
    dispatchForm,
    state.editorFlow?.drawflow,
    state.nodeId,
    translatedErrors,
    updateIntent,
    updateOutputs,
  ]);

  useEffect(() => {
    if (Object.keys(formState.formErrors).length > 0) {
      dispatchForm({ type: 'updateForm', data: { formErrors: {} } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  useEffect(() => {
    setCurrentTab(0);
  }, [blockGroups?.length]);

  useEffect(() => {
    if (!dataForm) return;

    if (!dataForm.apiInfo) {
      dataForm.apiInfo = {
        headers: [],
        body: '',
        params: [],
        query: [],
        method: 'GET',
        url: '',
      };
    }

    if (!dataForm.bubbleInfo || !dataForm.bubbleInfo?.bubbleId) {
      dataForm.bubbleInfo = {
        bubbleId: '',
        bubbleVersion: null
      };
    }

    if (!dataForm.ai123Info) {
      dataForm.ai123Info = {
        contentGroupId: ''
      };
    }

    if (!dataForm.blockMode) {
      dataForm.blockMode = 'PASSIVE'
    }
  }, [dataForm]);

  if (!state.openModalFormsNewEditor || !dataForm) return null;

  function handleClose() {
    dispatch({ type: 'closeModalFormsNewEditor' });
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const renderParams = () => {
    return <UserParameters />;
  };

  const handleChangeOrientation = () => {
    if (dataForm) {
      dataForm.listType =
        dataForm.listType === EListType.Horizontal
          ? EListType.Vertical
          : EListType.Horizontal;

      dispatchForm({ type: 'updateForm', data: { dataForm } });
    }
  };

  const handleChangeSaveVarValue = (value: boolean) => {
    if (dataForm) {
      dataForm.saveVarValue = value;
      dispatch({
        type: 'updateForm',
        data: {
          dataForm: {
            ...dataForm,
          },
        },
      });
    }
  };

  const renderOutputVar = (usesOutputVar?: boolean) => {
    if (
      dataForm.metadata?.type === 'AI123' &&
      dataForm.blockMode &&
      dataForm.blockMode !== 'PASSIVE'
    ) {
      return null;
    };

    return (
      usesOutputVar && (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={dataForm?.saveVarValue}
                color="primary"
                onChange={(e) => {
                  handleChangeSaveVarValue(e.target.checked);
                }}
              />
            }
            label={getTranslation('saveSelectedOption')}
            color="primary"
          />
        </FormGroup>   
      )
    )
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: true,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: true,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          <span>{getTranslation('save')}</span>
          
          {loading && (
            <CircularProgress
              color="inherit"
              size={18}
              style={{ marginLeft: 8 }}
            />
          )}
        </Button>

        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        {blockGroups?.map((group, index) => (
          <Tab key={group.label} label={group.label} {...a11yProps(index)} />
        ))}
      </Tabs>

      <div style={{ padding: 8 }}>
        {blockGroups?.map((group, index) => (
          <React.Fragment key={group.label}>
            {group.children.map((child, childIndex) => (
              <TabPanel key={childIndex} value={currentTab} index={index}>
                <GroupChild {...child} />
              </TabPanel>
            ))}
          </React.Fragment>
        ))}

        <TabPanel key={0} value={currentTab} index={0}>
          {renderOutputVar(dataForm.metadata?.usesOutputVar)}

          {dataForm.saveVarValue && (
            <GlobalVariablesSelection showTitle noPadding />
          )}

          {dataForm.metadata?.usesParams && renderParams()}

          {dataForm?.metadata?.type === 'Message' && (
            <TypeOfMessage metaBlock />
          )}

          {(dataForm?.metadata?.type === 'Carousel' ||
            dataForm?.metadata?.type === 'SimpleCarousel') && (
            <RadioGroup
              row
              value={dataForm?.listType === EListType.Vertical ? true : false}
              onChange={handleChangeOrientation}
            >
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label={getTranslation('horizontalCarousel')}
              />
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label={getTranslation('verticalCarousel')}
              />
            </RadioGroup>
          )}
        </TabPanel>
      </div>
    </Drawer>
  );
}
