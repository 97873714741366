import { Typography } from '@material-ui/core';
import MultipleTags from 'components/inputs/MultipleTags';
import { Child, Item } from 'contexts/Flow/types';
import { useForm } from 'contexts/Form/formContext';
import React from 'react';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';
import { GroupChildKeys } from './GroupChildKeys';


export function GroupChild({ items, title }: Child) {
  const { getTranslation } = useTranslator();
  const { state: { dataForm } } = useForm()

  const renderItems = (items: Item[], title?: string) => {
    const translatedTitle = getTranslation(title as getTranslationKeys);

    return (
      items.map((item, index) => {
        const ai123EntriesToHidden = item.sendUser

        if (
          dataForm?.metadata?.type === 'AI123' &&
          dataForm.blockMode &&
          dataForm.blockMode !== 'PASSIVE' &&
          ai123EntriesToHidden
        ) {
          return null;
        };

        return (
          <div key={index} style={{ marginBottom: '16px' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {translatedTitle}
            </Typography>
    
            <GroupChildKeys {...item} />
          </div>
        )
      })
    )
  };

  return (
    <React.Fragment>
      {title === 'Tags' ? (
        <>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <MultipleTags hideTitle inColumns noPadding />
        </>
      ) : (
        renderItems(items, title)
      )}
    </React.Fragment>
  );
}
