import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useApp } from 'contexts/App/appContext';
import { useFlow } from 'contexts/Flow/flowContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GetBotsGroups } from 'services/BotService';
import {
  GetGroupIsLiberated,
  ReleaseGroupAccess,
  UpdateLastAccess,
} from 'services/FlowService';
import {
  AutoCompleteOption,
  AutoCompleteWrap,
  BotAndGroupInfo,
  ImportExportIcons,
  LabelTitle,
  StyledMttButtonPurple,
} from '../styles';

import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { localStore } from 'utils/Stores';

import { CloudDownload, CloudUpload } from '@material-ui/icons';

import { useBuilder } from 'contexts/Builder';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { BubbleSchema } from 'contexts/Flow/types';
import { usePermissions } from 'contexts/Permissions/permissionsContext';
import { useToast } from 'contexts/ToastContext';
import { GetBubbles } from 'services/BubblesService';
import { GetProject } from 'services/CompaniesService/Projects';
import { IProjectResponse } from 'services/CompaniesService/Projects/types';
import { GetFlags } from 'services/FlagsService';
import { GetFlexBlocks } from 'services/FlexBlocksService';
import useTranslator from 'utils/hooks/Translator';
import { exportGroup, importGroup } from 'utils/ImportExportGroup';

const RenderBots = () => {
  const { setCurrentData, currentData, handleChangeCurrentElementName } =
    useFintalkCloud();
  const { toastNotification } = useToast();
  const { dispatch: builderDisptach } = useBuilder();
  const { dispatch, saveFlow, state, loadFlow, mountNodeContent, stopEditing } =
    useFlow();

  const { hasPermissionToAction } = usePermissions();
  const dispatchApp = useApp().dispatch;

  const [project, setProject] = useState<IProjectResponse | null>(null);

  const { groupsNames } = state;
  const [groupsNamesLocal, setGroupsNamesLocal] = useState<string[]>([]);
  const defaultFilterOptions = createFilterOptions<any>();

  const { getTranslation } = useTranslator();
  const closeTranslated = getTranslation('close');
  const openTranslated = getTranslation('open');
  const noResultsTranslated = getTranslation('noResults');
  const { projectName, companyName, agentName, groupName } = currentData;
  const botName = currentData.agentName;

  const getFlags = useCallback(
    async (agent: string) => {
      const flagsResponse = await GetFlags(agent, dispatchApp);
      let flags;

      if (flagsResponse.Success) {
        flags = flagsResponse.Data.data.flags || [];

        localStorage.setItem('@fintalkCloud:flags', JSON.stringify(flags))
      }

      return flags;
    },
    [dispatchApp]
  );

  const getBubbles = useCallback(async (agent: string) => {
    const bubblesResponse = await GetBubbles(agent, dispatchApp);
    let bubbles: BubbleSchema[] = []

    if (bubblesResponse.Success) {
      bubbles = bubblesResponse.Data.data
    }

    return bubbles;
  }, [dispatchApp])

  const getAgentData = useCallback(
    async (agent: string) => {
      await releaseGroup();

      const flags = await getFlags(agent);

      const response = await GetBotsGroups({ bot_name: agent }, dispatchApp);

      const bubbles = await getBubbles(agent);

      let groups = response.Data.data;

      if (Array.isArray(groups)) {
        const idGroup =
          groups.find((group) => group === groupName) || 'principal';

        const isFlowRead = hasPermissionToAction({
          company: companyName || '',
          agent,
          action: ['flow:read', 'flow:write', 'flow:publish'],
        });

        if (idGroup && isFlowRead) {
          dispatch({ type: 'updateForm', data: { loading: true } });

          await loadFlow(idGroup, agent);
          localStorage.setItem('@fintalkCloud:group', idGroup);
        } else {
          state.editorFlow?.clear();
        }

        localStorage.setItem('@fintalkCloud:agent', agent);

        dispatch({
          type: 'updateForm',
          data: {
            companyName,
            botName: agent,
            idGroup,
            groupsNames: groups,
            loading: false,
            flags,
            bubbles,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      companyName,
      dispatch,
      dispatchApp,
      getFlags,
      groupName,
      hasPermissionToAction,
      loadFlow,
      state.editorFlow,
    ]
  );

  const loadProject = useCallback(
    async (companyName, projectName) => {
      if (!companyName || !projectName) {
        handleChangeCurrentElementName('home');
        return;
      }

      const response = await GetProject(
        { projectName, companyName },
        dispatchApp
      );

      if (response.Success) {
        const project = response.Data;
        setProject(project);
        const agentToLoadIndex = project.agents.findIndex(
          (agent) => agent === agentName
        );

        localStorage.setItem('@fintalkCloud:project', project.name);
        const indexToLoad = agentToLoadIndex > 0 ? agentToLoadIndex : 0;
        const agent = project.agents[indexToLoad];
        if (!agent) {
          setCurrentData((prev) => ({ ...prev, agentName: '', groupName: '' }));
          state.editorFlow?.clear();
          if (state.isEditing) stopEditing();
          return;
        }
        await getAgentData(agent);
        return;
      }
      setCurrentData((prev) => ({
        ...prev,
        agentName: '',
        groupName: '',
        projectName: localStorage.getItem('@fintalkCloud:project') ?? '',
      }));
      state.editorFlow?.clear();
      toastNotification({
        type: 'error',
        message: `Erro ao buscar projeto ${projectName}, carregando projeto anterior.`,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData.projectName, agentName, dispatchApp, getAgentData]
  );

  useEffect(() => {
    async function initialize() {
      if (!state.editorFlow) return;
      loadProject(companyName, projectName);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, projectName, state.editorFlow]);

  const isFlowWrite = hasPermissionToAction({
    company: state.companyName || '',
    agent: state.botName || '',
    action: ['flow:write'],
  });

  const agents = useMemo(() => {
    return project?.agents?.sort();
  }, [project?.agents]);

  useEffect(() => {
    setGroupsNamesLocal(groupsNames.map((group) => group));
  }, [groupsNames]);

  const fixGroupsNamesOrder = useCallback(() => {
    const curGroups = groupsNamesLocal;

    const principalIndex = curGroups.findIndex(
      (group) => group === 'principal'
    );
    const principal = curGroups[principalIndex];

    if (principalIndex >= 0) {
      curGroups.splice(principalIndex, 1);
      setGroupsNamesLocal([
        principal,
        ...curGroups.map((group) => group).sort(),
      ]);
    } else {
      setGroupsNamesLocal(curGroups.map((group) => group).sort());
    }
  }, [groupsNamesLocal]);

  const releaseGroup = async () => {
    if (currentData.agentName && currentData.groupName && state.isEditing) {
      await ReleaseGroupAccess(
        {
          bot_name: currentData.agentName,
          groupName: currentData.groupName,
        },
        dispatchApp
      );
    }

    state.botsUrls.urlFacebook = '';
    state.botsUrls.urlFalazap = '';
    state.botsUrls.urlTelegram = '';
    state.botsUrls.urlTwitter = '';
    state.botsUrls.urlWhatsApp = '';
  };

  const handleChangeAgent = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      textContent: unknown;
    }>
  ) => {
    const botNameSelected = String(e.target.textContent);
    dispatch({ type: 'updateForm', data: { loading: true } });
    await updateFlow();
    await getAgentData(botNameSelected);
  };

  const handleChangeGroup = async (
    e: React.ChangeEvent<{
      name?: string | undefined;
      textContent: unknown;
    }>
  ) => {
    if (!e || !e.target.textContent || !state.editorFlow) return;

    const idGroup = String(e.target.textContent);

    await changeGroupFlow(idGroup);
  };

  const changeGroupFlow = async (group_id: string, deleteGroup = false) => {
    dispatch({ type: 'updateForm', data: { loading: true } });

    if (state.isEditing) {
      !deleteGroup && (await updateFlow());

      await ReleaseGroupAccess(
        {
          bot_name: botName || '',
          groupName: state.idGroup || '',
        },
        dispatchApp
      );
    }

    await loadFlow(group_id, botName || '', false, deleteGroup);

    localStorage.setItem('@fintalkCloud:group', group_id);

    dispatch({
      type: 'updateForm',
      data: { idGroup: group_id, loading: false },
    });
  };

  const updateLastAccess = async () => {
    const groupLastVersion = localStore.get('groupLastVersion') || '';

    if (
      !!state.editorFlow &&
      state.isEditing &&
      JSON.stringify(state.editorFlow.drawflow) !== groupLastVersion
    ) {
      const sBotAndGroup = localStore.get('current_bot_group');

      if (sBotAndGroup) {
        const { botName, groupName } = JSON.parse(sBotAndGroup);

        if (botName && groupName) {
          await UpdateLastAccess(
            {
              bot_name: botName,
              groupName,
            },
            dispatchApp
          );
        }
      }
    }
  };

  const updateFlow = async () => {
    await updateLastAccess();
    await saveFlow();
  };

  function handleAddNewGroup() {
    if (botName) {
      dispatch({ type: 'openModalCreateGroup' });
    } else {
      toastNotification({
        type: 'warning',
        message: '*Selecione um bot para criar uma grupo!',
      });
    }
  };

  const handleOpenModalDeleteGroup = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    group: string
  ) => {
    e.stopPropagation();

    if (botName) {
      const isFlexGroup = group.startsWith('flex-');
      if (isFlexGroup) {
        const flexBlocksResponse = await GetFlexBlocks(
          state.botName!,
          dispatchApp
        );

        if (flexBlocksResponse.Data.data) {
          const flexBlocks = flexBlocksResponse.Data.data;
          const flexBlockBeingDeleted = flexBlocks.flex_blocks.find(
            (block) => `flex-${block.block_name}` === group
          );
          if (
            flexBlockBeingDeleted &&
            flexBlockBeingDeleted.groups_used.length > 0
          ) {
            const groups = flexBlockBeingDeleted.groups_used.map(
              (group) => group.group_name
            );

            toastNotification({
              type: 'error',
              message: `Não foi possível apagar esse grupo. Blocos Flex referentes a ele estão em uso no(s) grupo(s) "${groups}".`,
            });
            return;
          }
        }
      }

      const response = await GetGroupIsLiberated(
        {
          bot_name: botName,
          groupName: group,
        },
        dispatchApp
      );
      if (!response.Data.group_is_liberated) {
        toastNotification({
          type: 'error',
          message: getTranslation('toast.error.agentOrGroupInUse', {
            object: getTranslation('group'),
            user: response.Data.current_user,
            status: getTranslation('agentOrGroupStatus.removed'),
          }),
        });
      } else {
        const selectedGroup = groupsNames.find(
          (newGroup) => newGroup === group
        );
        if (selectedGroup)
          builderDisptach({
            type: 'setGroupToDelete',
            data: { groupToDelete: { id: selectedGroup, name: selectedGroup } },
          });
      }
    }
  };

  const importFileDefault = () => {
    const button = document.getElementById(
      'import-button'
    ) as HTMLButtonElement;
    if (button) {
      const disabled = button.disabled;

      if (!disabled) {
        importGroup(state, toastNotification, mountNodeContent, {
          invalidFileForGroup: getTranslation(
            'toast.error.invalidFileForGroup'
          ),
          invalidFileForPrincipal: getTranslation(
            'toast.error.invalidFileForPrincipal'
          ),
          onlyValidForPrincipal: getTranslation(
            'toast.error.onlyValidForPrincipal'
          ),
        });
      }
    }
  };

  const exportFileDefault = () => {
    exportGroup(state);
  };

  return (
    <>
      <BotAndGroupInfo>
        <AutoCompleteWrap>
          <LabelTitle>{getTranslation('agent')}</LabelTitle>

          <Autocomplete
            value={currentData.agentName}
            onClose={() =>
              setCurrentData((prev) => ({
                ...prev,
                agentName: prev.agentName || '',
              }))
            }
            options={Array.from(new Set(agents)).sort()}
            disabled={!companyName}
            noOptionsText={noResultsTranslated}
            renderOption={(option) => (
              <AutoCompleteOption>{option}</AutoCompleteOption>
            )}
            onChange={(event: any) => {
              handleChangeAgent(event);
            }}
            style={{ width: '100%' }}
            openText={openTranslated}
            closeText={closeTranslated}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={getTranslation('selectAgent')}
              />
            )}
          />
        </AutoCompleteWrap>

        <AutoCompleteWrap>
          <Typography variant="body1">{getTranslation('group')}</Typography>
          <Autocomplete
            id="group-autocomplete"
            style={{
              width: '100%',
            }}
            disabled={!currentData.agentName}
            onOpen={fixGroupsNamesOrder}
            options={groupsNamesLocal}
            value={currentData.groupName}
            noOptionsText={noResultsTranslated}
            onChange={(event: any) => {
              const addGroup = getTranslation('addGroup');
              if (event.target.textContent.trim() !== addGroup) {
                handleChangeGroup(event);
              }
            }}
            renderOption={(option) =>
              !option || typeof option !== 'string' ? (
                option
              ) : (
                <React.Fragment>
                  <AutoCompleteOption>{option}</AutoCompleteOption>
                  {option !== 'principal' && (
                    <>
                      {isFlowWrite && (
                        <button
                          style={{
                            margin: 4,
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          onClick={(e) => handleOpenModalDeleteGroup(e, option)}
                        >
                          <DeleteIcon style={{ color: '#AEAEAE' }} />
                        </button>
                      )}
                    </>
                  )}
                </React.Fragment>
              )
            }
            openText={openTranslated}
            closeText={closeTranslated}
            filterOptions={(options, state) => {
              const results = defaultFilterOptions(options, state);
              return [
                ...results,
                <>
                  {isFlowWrite && (
                    <>
                      <StyledMttButtonPurple
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={handleAddNewGroup}
                        fullWidth
                        disabled={!botName}
                      >
                        {getTranslation('addGroup').trim()}
                      </StyledMttButtonPurple>
                    </>
                  )}
                </>,
              ];
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={getTranslation('selectGroup')}
                />
              </>
            )}
          />
        </AutoCompleteWrap>
      </BotAndGroupInfo>

      {!!botName && !currentData.groupName?.startsWith('flex') && (
        <ImportExportIcons>
          {state.isEditing && (
            <Tooltip title={getTranslation('tooltip.uploadGroup')}>
              <IconButton
                id="import-button"
                aria-label="import-file"
                onClick={() => importFileDefault()}
              >
                <CloudUpload />
              </IconButton>
            </Tooltip>
          )}
          
          <Tooltip title={getTranslation('tooltip.downloadGroup')}>
            <IconButton
              aria-label="download-file"
              onClick={() => exportFileDefault()}
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </ImportExportIcons>
      )}
    </>
  );
};
export default RenderBots;
